import React, { memo } from 'react';

import { Notice as NoticeType } from '@AuroraTypes';
import { hastToReact } from '@UX/Hast/Hast';
import { Notice } from '@UX/Notice/Notice';
import { Paragraph } from '@UX/Text/Paragraph/Paragraph';

interface PageNoticeComponentProps {
  notices: Pick<NoticeType, 'type' | 'content'>[];
}

export const PageNoticeComponent: React.FC<PageNoticeComponentProps> = memo(({ notices }) =>
  notices.length === 0 ? null : (
    <div
      sx={{
        marginBottom: '3xs',
      }}
    >
      {notices.map(
        (notice, index) =>
          notice && (
            <Notice
              key={index}
              type={notice.type}
            >
              {hastToReact(notice.content, {
                a: {
                  Component: 'a',
                  Props: {
                    target: '_blank',
                  },
                },
                p: {
                  Component: Paragraph,
                  Props: {
                    sx: {
                      marginBottom: 0,
                      marginTop: 0,
                      fontSize: 's',
                    },
                  },
                },
              })}
            </Notice>
          ),
      )}
    </div>
  ),
);
