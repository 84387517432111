import { PageContainer, Breadcrumbs, Link, InlineList } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useMemo } from 'react';

import { Breadcrumb } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';

interface SubHeaderLinksProps {
  breadcrumbs: Breadcrumb[];
  showBreadcrumbsOnMobile?: boolean;
}

export const SubHeaderLinks: React.FC<SubHeaderLinksProps> = ({
  breadcrumbs,
  showBreadcrumbsOnMobile = false,
}) => {
  const { t } = useTranslation();
  const { site } = useAppContext();

  const hasBreadcrumbs = !!breadcrumbs.length;
  const breadrumbsJson = JSON.stringify(breadcrumbs);

  return useMemo(
    () => (
      <PageContainer
        sx={{
          display: [showBreadcrumbsOnMobile && hasBreadcrumbs ? 'grid' : 'none', 'grid'],
          justifyContent: hasBreadcrumbs ? 'space-between' : 'end',
          gap: 's',
          alignItems: 'start',
          gridTemplateColumns: hasBreadcrumbs ? 'auto auto' : 'auto',
          marginY: 'xs',
          a: {
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        {hasBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

        {site.active && (
          <InlineList
            sx={{
              display: ['none', 'flex'],
              flexWrap: 'wrap',
              justifyContent: 'end',
            }}
            items={[
              <Link
                data-id="mmb-subheader-link"
                key="manageMyBooking"
                href="/customer/bookings/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {t('manageMyBooking')}
              </Link>,
              <Link
                data-id="faq-subheader-link"
                key="faq"
                href="/faq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('faq')}
              </Link>,
            ]}
          />
        )}
      </PageContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadrumbsJson],
  );
};
