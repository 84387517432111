import { Icons, Icon, PageContainer } from '@loveholidays/design-system';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { ColorsPalette } from '@UX/themes/types';

type NoticeType = 'info' | 'warning' | 'urgent';

interface NoticeProps extends ClassNameProps {
  type: NoticeType;
  dismissable?: boolean;
}

const noticeTypeToIcon: Record<NoticeType, Icons> = {
  warning: 'Markers/Alert',
  info: 'Markers/Tooltip',
  urgent: 'Markers/Disruption',
};

const noticeTypeToBackgroundColor: Record<NoticeType, keyof ColorsPalette> = {
  urgent: 'backgroundCriticallight',
  warning: 'backgroundWarningmedium',
  info: 'backgroundInformationlight',
};

export const Notice: React.FC<NoticeProps> = ({ type, children, dismissable = false }) => (
  <div
    sx={{
      paddingY: 'l',
      backgroundColor: noticeTypeToBackgroundColor[type],
    }}
  >
    <PageContainer>
      <div
        sx={{
          display: 'flex',
          alignItems: ['flex-start', 'center'],
        }}
      >
        <Icon
          name={noticeTypeToIcon[type]}
          size="28"
          sx={{
            marginRight: '3xs',
            display: 'flex',
          }}
        />
        {children}
        {dismissable && <div>Dismiss</div>}
      </div>
    </PageContainer>
  </div>
);
